import "./About.css";
import meBlue from "../../img/meBlue.jpg";

const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
        <img src={meBlue} alt="" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          Seven years ago, I left the classroom.
        </p>
        <p className="a-desc">
          I built my own English teaching business which allowed me to reach beyond the four walls of my classroom in order to help students from all over the world. My remote business allowed me to live on three continents and in over twenty countries. When I first encountered coding, I was fascinated by the possibility of harnassing its potential in order to help more students. While I am still passionate about this path, coding has opened my eyes to an even wider realm of possibilities to have a positive impact in a variety of fields. 
        </p>
      </div>
    </div>
  );
};

export default About;