export const projects = [
    {
      id: 1,
      img: "https://i.ibb.co/Qb4sxM0/d999a6af-d237-4a13-94de-e4440c20aac7.jpg",
      link: "https://github.com/sherryliztynan/order-book",
    },
    {
      id: 2,
      img: "https://i.ibb.co/Yjv1yB5/82e2487c-6cf5-44ef-bd5b-9ad17b178c70.jpg",
      link: "https://github.com/Open-Avenues-Foundation/P21020-2",
    },
    {
      id: 3,
      img: "https://i.ibb.co/nrCgQqk/b0118cf4-bfb5-4e69-890d-9523011d3421.jpg",
      link: "https://github.com/sherryliztynan/yoga-connect-ui",
    },
  ];