import "./SocialMedia.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

const SocialMedia = () => {
return (

<div class="social-container">
<a href="https://www.linkedin.com/in/sherry-elizabeth/" target="_blank" rel="noreferrer"
  className="linkedin social"><FaLinkedin />
</a>
<a href="https://github.com/sherryliztynan" target="_blank" rel="noreferrer"
  className="github social"> <FaGithub />
</a>
<a href="mailto:contact@sherrontynan.com" target="_blank" rel="noreferrer"
    className="email social"> <FaEnvelope />
</a>
</div>
    )
    }

export default SocialMedia;